<template>
  <div class="page-background d-flex justify-center">
    <v-card
      :width="width"
      :max-width="maxWidth"
      height="100%"
      class="mx-auto my-8 pt-0 pb-3 px-3"
      id="pageContainer"
    >
      <v-card-text class="py-0 my-0">
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PageLayout",
  props: {
    maxWidth: {
      type: [String, Number],
      value: "600px",
    },
    width: {
      type: [String, Number],
      value: "600px",
    },
  },
};
</script>
