import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import Param from "@/components/shared/Param";
import { escape, unescape } from "lodash";

export default Node.create({
  name: "param",
  group: "inline",
  inline: true,
  selectable: true,
  draggable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "span[data-param]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes),
      `{{${unescape(HTMLAttributes["data-param"])}}}`,
    ];
  },
  addAttributes() {
    return {
      param: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-param"),
        renderHTML: (attributes) => ({
          "data-param": escape(attributes.param),
        }),
      },
      class: {
        default: null,
      },
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(Param);
  },
});
