var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-background d-flex justify-center" },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto my-8 pt-0 pb-3 px-3",
          attrs: {
            width: _vm.width,
            "max-width": _vm.maxWidth,
            height: "100%",
            id: "pageContainer",
          },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "py-0 my-0" },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }